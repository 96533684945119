import { useState, useCallback } from 'react';

interface IUsePrevNextProps {
    step: number;
    initialValue?: number;
}

interface IUsePrevNext {
    changeHandler: (isNext: boolean) => void;
    isVisible: (index: number) => boolean;
    visibleIndex: number;
    changeVisibleIndex: (index: number) => void;
}

const usePrevNextVisible: (props: IUsePrevNextProps) => IUsePrevNext = ({ step, initialValue }) => {
    const [visibleIndex, setVisibleIndex] = useState<number>(initialValue !== undefined ? initialValue : 0);

    const changeHandlerCallback = useCallback(
        (isNext: boolean) => {
            if (isNext) {
                setVisibleIndex((visibleIndex) => visibleIndex + step);
            } else {
                setVisibleIndex((visibleIndex) => visibleIndex - step);
            }
        },
        [visibleIndex, step]
    );

    const isVisibleCallback = useCallback(
        (index: number) => {
            let isVisible = false;
            Array.from(Array(step).keys()).forEach((item) => {
                if (visibleIndex + item === index) isVisible = true;
            });
            return isVisible;
        },
        [visibleIndex, step]
    );

    const changeVisibleIndex = useCallback((index: number) => {
        setVisibleIndex(index);
    }, []);

    return {
        changeHandler: changeHandlerCallback,
        isVisible: isVisibleCallback,
        visibleIndex,
        changeVisibleIndex
    };
};

export default usePrevNextVisible;
