import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { isBefore } from 'date-fns';
import { useTheme } from '@mui/system';
import {
    useGetInsightsOrganizationOutcomesSkillsQuery,
    useGetInsightsOrganizationOutcomesUsersQuery
} from '../services/InsightsQueryService';
import { IScoreLine } from '../interfaces/IScoreLine';
import { createMonthsArray } from '../utils/createMonthsArray';
import { isGraphMonthLabelMatch } from '../utils/isGraphMonthLabelMatch';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import useFilterSearch from '../hooks/useFilterSearch';
import {
    insightsOrganizationOutcomesUsersByDepartmentFilterCallback,
    insightsOrganizationOutcomesUsersByRegionFilterCallback,
    insightsOrganizationOutcomesUsersCategories
} from './util/filterCategories';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import { IInsightsOrganizationOutcomesSkillVM } from '../interfaces/views/IInsightsOrganizationOutcomesSkillVM';
import { IInsightsOrganizationOutcomesUserVM } from '../interfaces/views/IInsightsOganizationOutcomesUserVM';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { useInsightsStateValue } from './InsightsContext';
import { insightsScoresPerDateSelector, selectScoreLinePerMonth } from '../utils/insightsGraphDataSelector';

export enum EOrgOutcomesGraphOption {
    SKILLS = 'Skills',
    USERS = 'Users'
}
export interface IInsightsOrganizationalOutcomesDetailsContext {
    dataSkills: IInsightsOrganizationOutcomesSkillVM[];
    dataUsers: IInsightsOrganizationOutcomesUserVM[];
    skillTableData: IInsightsOrganizationOutcomesSkillVM[];
    userTableData: IInsightsOrganizationOutcomesUserVM[];
    scoreLines?: IScoreLine[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    filterCategories: IFilterCategories[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    skillGraphScoreLines: IScoreLine[];
    isSkillsDataLoading: boolean;
    isUsersDataLoading: boolean;
    outcomeId?: string;
    selectedGraphOption: EOrgOutcomesGraphOption;
    setSelectedGraphOption: (option: EOrgOutcomesGraphOption) => void;
}

export const InsightsOrganizationOutcomesDetailsContext = createContext<IInsightsOrganizationalOutcomesDetailsContext>(
    {} as IInsightsOrganizationalOutcomesDetailsContext
);

interface IProps {}

export enum EOutcomesDetailsScoreLineType {
    SKILLS = 'Skills',
    USERS = 'Users'
}

const colors = [
    '#5C4F9C',
    '#FFCC55',
    '#B0CB3E',
    '#349FEE',
    '#FE6C6C',
    '#BDB37E',
    '#CE9BD6',
    '#8FDFA1',
    '#B6B6B6',
    '#000',
    '#AAA',
    '#DDD',
    '#FF0',
    '#F0F',
    '#0FF',
    '#F00',
    '#0F0',
    '#00F'
];

interface IInsightsOrganizationOutcomesSkillColorVM extends IInsightsOrganizationOutcomesSkillVM {
    color: string;
}

export const InsightsOrganizationOutcomesDetailsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const params = useParams<{ id?: string }>();
    // Data used for Insights Organizational Skills graph
    const [dataSkills, setDataSkills] = useState<IInsightsOrganizationOutcomesSkillVM[]>([]);
    const [filteredDataSkills, setFilteredDataSkills] = useState<IInsightsOrganizationOutcomesSkillVM[]>([]);
    // Data used for Insights Organizational Skills table
    const [filteredSkillTableData, setFilteredSkillTableData] = useState<IInsightsOrganizationOutcomesSkillColorVM[]>(
        []
    );
    // ---

    // Data used for Insights Organizational Users graph
    const [dataUsers, setDataUsers] = useState<IInsightsOrganizationOutcomesUserVM[]>([]);
    const [filteredDataUsers, setFilteredDataUsers] = useState<IInsightsOrganizationOutcomesUserVM[]>([]);
    // ---
    // Data used for Insights Organizational Users table
    const [filteredUserTableData, setFilteredUserTableData] = useState<IInsightsOrganizationOutcomesUserVM[]>([]);
    // ---

    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(
        insightsOrganizationOutcomesUsersCategories
    );
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const { data: dataSkillsFetched, isLoading: isSkillsDataLoading } = useGetInsightsOrganizationOutcomesSkillsQuery(
        params?.id
    );
    const { data: dataUsersFetched, isLoading: isUsersDataLoading } = useGetInsightsOrganizationOutcomesUsersQuery(
        params?.id
    );
    const [filteredUserCompetenceMapByDate, setFilteredUserCompetenceMapByDate] = useState<
        Map<string, IInsightsOrganizationOutcomesUserVM[]>
    >(new Map());
    const [filteredSkillCompetenceMapByDate, setFilteredSkillCompetenceMapByDate] = useState<
        Map<string, IInsightsOrganizationOutcomesSkillVM[]>
    >(new Map());
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const [selectedGraphOption, setSelectedGraphOption] = useState<EOrgOutcomesGraphOption>(
        EOrgOutcomesGraphOption.USERS
    );
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const skillScoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const [skillGraphScoreLines, setSkillGraphScoreLines] = useState<IScoreLine[]>([]);
    const activeTimePeriodOptionRef = useRef<EGraphCardSelect>(EGraphCardSelect.MONTH_1);
    const theme = useTheme();
    const { setSelectedOutcomeName } = useInsightsStateValue();

    const generateMapByDateBasedOnData: (
        data: IInsightsOrganizationOutcomesUserVM[] | IInsightsOrganizationOutcomesSkillVM[]
    ) => Map<string, IInsightsOrganizationOutcomesUserVM[] | IInsightsOrganizationOutcomesSkillVM[]> | undefined = (
        data
    ) => {
        if (data) {
            if (data.length > 0) setSelectedOutcomeName(data[0].outcomeName);
            const competenceMapByDate = new Map();
            data.forEach((item) => {
                if (item.date && competenceMapByDate.has(item.date.toDateString())) {
                    competenceMapByDate.set(item.date.toDateString(), [
                        ...competenceMapByDate.get(item.date.toDateString()),
                        item
                    ]);
                } else {
                    competenceMapByDate.set(item.date.toDateString(), [item]);
                }
            });
            return competenceMapByDate;
        }
        return;
    };

    useEffect(() => {
        if (filteredDataUsers) {
            // const filteredUserIdList = filteredDataUsers.map((item) => item.userId);
            // setFilteredLatestDateData(
            //     latestDateData.filter((dataItem) => {
            //         return filteredUserIdList.includes(dataItem.userId);
            //     })
            // );
            const competenceMapByDate = generateMapByDateBasedOnData(
                filteredDataUsers.sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
            );
            if (competenceMapByDate) {
                setFilteredUserCompetenceMapByDate(
                    competenceMapByDate as Map<string, IInsightsOrganizationOutcomesUserVM[]>
                );
            }
        }
    }, [filteredDataUsers]);

    useEffect(() => {
        if (dataUsers && dataUsers.length > 0) {
            const competenceMapByDate = generateMapByDateBasedOnData(dataUsers);
            if (competenceMapByDate) {
                setFilteredUserCompetenceMapByDate(
                    competenceMapByDate as Map<string, IInsightsOrganizationOutcomesUserVM[]>
                );
            }
        }
    }, [dataUsers]);

    useEffect(() => {
        if (filteredDataSkills) {
            const competenceMapByDate = generateMapByDateBasedOnData(
                filteredDataSkills.sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
            );
            if (competenceMapByDate) {
                setFilteredSkillCompetenceMapByDate(
                    competenceMapByDate as Map<string, IInsightsOrganizationOutcomesSkillVM[]>
                );
            }
        }
    }, [filteredDataSkills]);

    useEffect(() => {
        if (dataSkills && dataSkills.length > 0) {
            const competenceMapByDate = generateMapByDateBasedOnData(dataSkills);
            if (competenceMapByDate) {
                setFilteredSkillCompetenceMapByDate(
                    competenceMapByDate as Map<string, IInsightsOrganizationOutcomesSkillVM[]>
                );
            }
        }
    }, [dataSkills]);

    useEffect(() => {
        if (dataUsers) {
            const departments = new Set<string>();
            const regions = new Set<string>();

            dataUsers.forEach((dataItem) => {
                if (dataItem.department) departments.add(dataItem.department);
                if (dataItem.region) regions.add(dataItem.region);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'department') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: insightsOrganizationOutcomesUsersByDepartmentFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'region') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    regions.forEach((region) => {
                        categoryValues.push({
                            key: region,
                            name: region,
                            callback: insightsOrganizationOutcomesUsersByRegionFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [dataUsers]);

    useEffect(() => {
        if (filteredUserCompetenceMapByDate && filteredUserCompetenceMapByDate.size > 0) {
            const assignedUserScoreLine: IScoreLine = {
                id: 'OW1',
                name: 'Assigned',
                color: theme.palette.status.assigned,
                type: EOutcomesDetailsScoreLineType.USERS,
                scores: []
            };

            const attainedUserScoreLine: IScoreLine = {
                id: 'OW2',
                name: 'Attained',
                color: theme.palette.status.attained,
                type: EOutcomesDetailsScoreLineType.USERS,
                scores: []
            };

            const inProgressUserScoreLine: IScoreLine = {
                id: 'OW3',
                name: 'In Progress',
                color: theme.palette.status.inProgress,
                type: EOutcomesDetailsScoreLineType.USERS,
                scores: []
            };

            const assignedUserScoreLineYear: IScoreLine = {
                ...assignedUserScoreLine,
                id: 'OY1',
                type: EOutcomesDetailsScoreLineType.USERS,
                scores: createMonthsArray()
            };

            const attainedUserScoreLineYear: IScoreLine = {
                ...attainedUserScoreLine,
                id: 'OY2',
                type: EOutcomesDetailsScoreLineType.USERS,
                scores: createMonthsArray()
            };

            const inProgressUserScoreLineYear: IScoreLine = {
                ...inProgressUserScoreLine,
                id: 'OY3',
                type: EOutcomesDetailsScoreLineType.USERS,
                scores: createMonthsArray()
            };

            if (filteredUserCompetenceMapByDate.size === 0) {
                setScoreLines((scoreLines) =>
                    scoreLines?.map((scoreLine) => {
                        return {
                            ...scoreLine,
                            scores: scoreLine.scores.map((score) => {
                                return {
                                    ...score,
                                    value: 0
                                };
                            })
                        };
                    })
                );
            }

            const lastDateFilteredUsers = Array.from(filteredUserCompetenceMapByDate.values()).pop();
            if (lastDateFilteredUsers) setFilteredUserTableData(lastDateFilteredUsers);
            else setFilteredUserTableData([]);

            filteredUserCompetenceMapByDate.forEach(function (mapValue, mapKey) {
                let assignedUsersPerDateTotal = 0;
                let attainedUsersPerDateTotal = 0;
                let inProgressUsersPerDateTotal = 0;

                mapValue.forEach((mapItem) => {
                    assignedUsersPerDateTotal += mapItem.skillsTotal;
                    attainedUsersPerDateTotal += mapItem.skillsAttained;
                    inProgressUsersPerDateTotal += mapItem.skillsNotAttained;
                });

                assignedUserScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: assignedUsersPerDateTotal
                });

                attainedUserScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: attainedUsersPerDateTotal
                });

                inProgressUserScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: inProgressUsersPerDateTotal
                });

                const assignedUserScoreLineMonth = assignedUserScoreLineYear.scores.find((scoreLineYear) => {
                    return isGraphMonthLabelMatch(mapValue[0].date, scoreLineYear.date);
                });
                const attainedUserScoreLineMonth = attainedUserScoreLineYear.scores.find((scoreLineYear) => {
                    return isGraphMonthLabelMatch(mapValue[0].date, scoreLineYear.date);
                });
                const inProgressUserScoreLineMonth = inProgressUserScoreLineYear.scores.find((scoreLineYear) => {
                    return isGraphMonthLabelMatch(mapValue[0].date, scoreLineYear.date);
                });

                mapValue.forEach((dataItem) => {
                    if (assignedUserScoreLineMonth) {
                        if (!assignedUserScoreLineMonth?.value) assignedUserScoreLineMonth.value = 0;
                        assignedUserScoreLineMonth.value = assignedUserScoreLineMonth.value + dataItem.attained;
                    }

                    if (attainedUserScoreLineMonth) {
                        if (!attainedUserScoreLineMonth?.value) attainedUserScoreLineMonth.value = 0;
                        attainedUserScoreLineMonth.value = attainedUserScoreLineMonth.value + dataItem.attained;
                    }

                    if (inProgressUserScoreLineMonth) {
                        if (!inProgressUserScoreLineMonth?.value) inProgressUserScoreLineMonth.value = 0;
                        inProgressUserScoreLineMonth.value = inProgressUserScoreLineMonth.value + dataItem.inProgress;
                    }
                });
                const allIndividualScoreTimes = [
                    expandDatesToLastYearPerWeek(assignedUserScoreLine),
                    expandDatesToLastYearPerWeek(attainedUserScoreLine),
                    expandDatesToLastYearPerWeek(inProgressUserScoreLine)
                ];
                const scoreLinesPerMonths = insightsScoresPerDateSelector(allIndividualScoreTimes);
                setScoreLines(scoreLinesPerMonths[2]);

                scoreLinesAllOptionsRef.current = scoreLinesPerMonths;
            });
        } else {
            setScoreLines([]);
        }
    }, [filteredUserCompetenceMapByDate]);

    useEffect(() => {
        if (filteredSkillCompetenceMapByDate && filteredSkillCompetenceMapByDate.size > 0) {
            const competenceMapBySkill = new Map<number, IInsightsOrganizationOutcomesSkillVM[]>();
            filteredDataSkills.forEach((item) => {
                if (item.date && competenceMapBySkill.has(item.skillId)) {
                    competenceMapBySkill.set(item.skillId, [...(competenceMapBySkill.get(item.skillId) || []), item]);
                } else {
                    competenceMapBySkill.set(item.skillId, [item]);
                }
            });

            const tableData: IInsightsOrganizationOutcomesSkillColorVM[] = [];
            const skillGraphScoreLines: IScoreLine[] = [];
            let index = 0;
            competenceMapBySkill.forEach((mapValue: IInsightsOrganizationOutcomesSkillVM[], key) => {
                const newestDate = mapValue.reduce(
                    (a, b) => {
                        return a.date > b.date ? a : b;
                    },
                    {
                        date: new Date(1970, 1, 1)
                    }
                ).date;

                const latestMapValueItem = mapValue.find((valueItem) => {
                    return valueItem.date === newestDate;
                });

                if (latestMapValueItem)
                    tableData.push({
                        ...latestMapValueItem,
                        color: colors[index]
                    });

                const scoreLine: IScoreLine = {
                    id: `${index + 1}`,
                    color: colors[index],
                    name: '',
                    tooltip: mapValue[0].skillName,
                    type: EOutcomesDetailsScoreLineType.SKILLS,
                    scores: mapValue.map((skill) => {
                        return {
                            date: skill.date,
                            value: skill.attained
                        };
                    })
                };

                skillGraphScoreLines.push(expandDatesToLastYearPerWeek(scoreLine));

                index += 1;
            });

            setFilteredSkillTableData(tableData);
            // setSkillGraphScoreLines(skillGraphScoreLines);

            const scoreLinesPerMonths = insightsScoresPerDateSelector(skillGraphScoreLines);
            setSkillGraphScoreLines(scoreLinesPerMonths[2]);

            skillScoreLinesAllOptionsRef.current = scoreLinesPerMonths;
        } else {
            setSkillGraphScoreLines([]);
        }
    }, [filteredSkillCompetenceMapByDate]);

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        activeTimePeriodOptionRef.current = option;
        if (scoreLinesAllOptionsRef.current.length >= 1) {
            setScoreLines(selectScoreLinePerMonth(scoreLinesAllOptionsRef.current, activeTimePeriodOptionRef.current));
            setSkillGraphScoreLines(
                selectScoreLinePerMonth(skillScoreLinesAllOptionsRef.current, activeTimePeriodOptionRef.current)
            );
        }
        if (scoreLinesAllOptionsRef.current.length >= 2) {
            setScoreLines(selectScoreLinePerMonth(scoreLinesAllOptionsRef.current, activeTimePeriodOptionRef.current));
            setSkillGraphScoreLines(
                selectScoreLinePerMonth(skillScoreLinesAllOptionsRef.current, activeTimePeriodOptionRef.current)
            );
        }
    };

    const onAnythingChange: any = useCallback(
        (organizationUsersDataNeedToBeFiltered: IInsightsOrganizationOutcomesUserVM[], runSearchText?: boolean) => {
            let newFilteredUsers = [...organizationUsersDataNeedToBeFiltered];

            if (runSearchText) {
                newFilteredUsers = instantFilterByText(searchText, newFilteredUsers);
                setFilteredDataUsers(newFilteredUsers);
            } else {
                onFilterValueChange(filterCategories, activeFilters, true);
                setFilteredDataUsers(newFilteredUsers);
            }
            return newFilteredUsers;
        },
        [
            dataUsers,
            // @ts-ignore
            searchText,
            // @ts-ignore
            activeFilters,
            filterCategories
        ]
    );

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<IInsightsOrganizationOutcomesUserVM>({
        data: dataUsers,
        dataSerachablePropertyName: 'displayName',
        setDataCallback: setFilteredDataUsers,
        onChangeCallback: onAnythingChange
    });

    useEffect(() => {
        if (dataSkillsFetched) {
            setDataSkills(dataSkillsFetched);
            setFilteredDataSkills(dataSkillsFetched);
        }
    }, [dataSkillsFetched]);

    useEffect(() => {
        if (dataUsersFetched) {
            setDataUsers(dataUsersFetched);
            setFilteredDataUsers(dataUsersFetched);
        }
    }, [dataUsersFetched]);

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => {
        setActiveFilters(activeFilters);
        let newFilteredData = [...dataUsers];
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && activeFilters.includes(filterCategoryValue.key)) {
                        newFilteredData = newFilteredData.filter((dataItem) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    dataItem,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredData, true);
    };
    const insightsOrganizationalOutcomesDetailsContext: IInsightsOrganizationalOutcomesDetailsContext = {
        dataUsers: filteredDataUsers,
        dataSkills: filteredDataSkills,
        scoreLines,
        searchText,
        setSearchText,
        changeScoreLinesInterval,
        filterCategories,
        onFilterValueChange,
        skillGraphScoreLines,
        skillTableData: filteredSkillTableData,
        userTableData: filteredUserTableData,
        isSkillsDataLoading,
        isUsersDataLoading,
        outcomeId: params.id,
        selectedGraphOption,
        setSelectedGraphOption
    };

    return (
        <InsightsOrganizationOutcomesDetailsContext.Provider value={insightsOrganizationalOutcomesDetailsContext}>
            {children}
        </InsightsOrganizationOutcomesDetailsContext.Provider>
    );
};

export const useInsightsOrganizationalOutcomesDetailsStateValue: () => IInsightsOrganizationalOutcomesDetailsContext =
    () => useContext(InsightsOrganizationOutcomesDetailsContext);
