import { ChangeEvent, FC, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { RootBox, StatusCircle, StatusCircleWrapper } from './Style';
import GraphCard from '../../../../components/graph/graph-card/GraphCard';
import Table from '../../../../../../ui/general/table/Table';
import { EGraphCardSelect } from '../../../../../../interfaces/enums/EGraphCardSelect';
import { ITableHeadCell } from '../../../../../../interfaces/ITableHeaderCell';
import Filters from '../../../../../../ui/filters/filters/Filters';
import {
    FiltersWrapper,
    SearchInputWrapper,
    StyledSearchInput,
    TableFiltersWrapper,
    TableWrapper
} from '../../../../../../pages/insights/Style';
import { FiltersProvider } from '../../../../../../contexts/FiltersContext';
import useInsightsTimelineOption from '../../../../../../hooks/useInsightsTimelineOption';
import { useInsightsOrganizationSingleBadgeStateValue } from '../../../../../../contexts/InsightsOrganizationalSingleBadgeContext';
import { useInsightsStateValue } from '../../../../../../contexts/InsightsContext';
import { IOrganizationalUserForBadgeVM } from '../../../../../../interfaces/views/IOrganizationalUserForBadgeVM';
import { mapInsightsGraphScoreDates } from '../../../../../../utils/insightsGraphDataSelector';

interface ITableHeader {
    image: string;
    displayName: string;
    department: string;
    region: string;
    shared: number;
    claimed: number;
    readyToClaim: number;
    needAttention: number;
}

const OrganizationalSingleBadgeTab: FC = () => {
    const {
        scoreLines,
        latestDateData,
        changeScoreLinesInterval,
        onFilterValueChange,
        filterCategories,
        isBadgesDataLoading,
        searchText,
        setSearchText
    } = useInsightsOrganizationSingleBadgeStateValue();
    const { activeTimelineOption, handleTimelineChange } = useInsightsTimelineOption({
        changedTimelineOptionCallback: changeScoreLinesInterval,
        initialTimelineOptionValue: EGraphCardSelect.MONTH_3
    });
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const { selectedBadgeName } = useInsightsStateValue();

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true
        },
        {
            id: 'displayName',
            label: 'Display Name',
            minWidth: '130px'
        },
        {
            id: 'department',
            label: 'Department'
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center'
        },
        {
            id: 'shared',
            label: 'Shared',
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color='#349FEE' />
                    </StatusCircleWrapper>
                );
            }
        },
        {
            id: 'claimed',
            label: 'Claimed',
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color={theme.palette.status.attained} />
                    </StatusCircleWrapper>
                );
            }
        },
        {
            id: 'readyToClaim',
            label: 'Ready to Claim',
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color='#5C4F9C' />
                    </StatusCircleWrapper>
                );
            }
        },
        {
            id: 'needAttention',
            label: 'Need Attention',
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color={theme.palette.status.needAttention} />
                    </StatusCircleWrapper>
                );
            }
        }
    ];

    const scoreLinesValuesMemo = useMemo(() => {
        if (isBadgesDataLoading || !scoreLines) return undefined;
        return scoreLines;
    }, [scoreLines, isBadgesDataLoading]);

    const xAxisMemo = useMemo(() => {
        if (isBadgesDataLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            return mapInsightsGraphScoreDates(scoreLines[0].scores);
        }
        return [];
    }, [scoreLines, activeTimelineOption, isBadgesDataLoading]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
            />
        );
    }, [onFilterValueChange, filterCategories]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <GraphCard
                    scoreLines={scoreLinesValuesMemo}
                    xAxis={xAxisMemo}
                    handleTimelineChange={handleTimelineChange}
                    translations={{ graphCardTitle: selectedBadgeName }}
                />
                <SearchInputWrapper className='search-box'>
                    <StyledSearchInput
                        placeholder='Search Users'
                        value={searchText || ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (setSearchText) setSearchText(e.target.value);
                        }}
                    />
                </SearchInputWrapper>
                <TableFiltersWrapper>
                    <TableWrapper>
                        <Table<IOrganizationalUserForBadgeVM, ITableHeader>
                            headCells={headCells}
                            data={latestDateData}
                            propertyKeys={headCells.map((headCell) => {
                                return headCell.id;
                            })}
                            tableTitlePlural=''
                            isFilterControlVisible={false}
                            isFilterDrawerOpen={false}
                            isLoading={isBadgesDataLoading}
                            isImageColumnPresent
                            imageColumnName='userId'
                            initialOrderBy='badgeName'
                            userImageInfoColumn='displayName'
                        />
                    </TableWrapper>
                    {!isLgDown && <FiltersWrapper>{filtersMemo}</FiltersWrapper>}
                </TableFiltersWrapper>
            </FiltersProvider>
        </RootBox>
    );
};

export default OrganizationalSingleBadgeTab;
