import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { isBefore } from 'date-fns';
import { useTheme } from '@mui/system';
import { useGetInsightsOrganizationSkillQuery } from '../services/InsightsQueryService';
import { IScoreLine } from '../interfaces/IScoreLine';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import useFilterSearch from '../hooks/useFilterSearch';
import {
    insightsOrganizationSkillByDepartmentFilterCallback,
    insightsOrganizationSkillByRegionFilterCallback,
    insightsOrganizationSkillCategories
} from './util/filterCategories';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import { IInsightsOrganizationSkillVM } from '../interfaces/IInsightsOrganizationSkillVM';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { useInsightsStateValue } from './InsightsContext';
import { insightsScoresPerDateSelector, selectScoreLinePerMonth } from '../utils/insightsGraphDataSelector';

export interface IInsightsOrganizationalSkillContext {
    data: IInsightsOrganizationSkillVM[];
    latestDateData: IInsightsOrganizationSkillVM[];
    scoreLines?: IScoreLine[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    filterCategories: IFilterCategories[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    isSkillDataLoading: boolean;
}

export const InsightsOrganizationalSkillContext = createContext<IInsightsOrganizationalSkillContext>(
    {} as IInsightsOrganizationalSkillContext
);

export const InsightsOrganizationalSkillProvider: FC<PropsWithChildren> = ({ children }) => {
    const params = useParams<{ outcomeId?: string; skillId?: string }>();
    const [data, setData] = useState<IInsightsOrganizationSkillVM[]>([]);
    const [filteredData, setFilteredData] = useState<IInsightsOrganizationSkillVM[]>([]);

    const [latestDateData, setLatestDateData] = useState<IInsightsOrganizationSkillVM[]>([]);
    const [filteredLatestDateData, setFilteredLatestDateData] = useState<IInsightsOrganizationSkillVM[]>([]);

    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(insightsOrganizationSkillCategories);
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const { data: skillData, isLoading: isSkillDataLoading } = useGetInsightsOrganizationSkillQuery(
        params.outcomeId,
        params.skillId
    );
    const [_, setSkillMapByDate] = useState<Map<string, IInsightsOrganizationSkillVM[]>>(new Map());
    const [filteredSkillMapByDate, setFilteredSkillMapByDate] = useState<Map<string, IInsightsOrganizationSkillVM[]>>(
        new Map()
    );
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const newestDateRef = useRef<Date>();
    const theme = useTheme();
    const { setSelectedSkillName } = useInsightsStateValue();

    const generateMapByDateBasedOnData: (
        data: IInsightsOrganizationSkillVM[]
    ) => Map<string, IInsightsOrganizationSkillVM[]> | undefined = (data) => {
        if (data) {
            const competenceMapByDate = new Map();
            data.forEach((item) => {
                if (item.date && competenceMapByDate.has(item.date.toDateString())) {
                    competenceMapByDate.set(item.date.toDateString(), [
                        ...competenceMapByDate.get(item.date.toDateString()),
                        item
                    ]);
                } else {
                    competenceMapByDate.set(item.date.toDateString(), [item]);
                }
            });
            return competenceMapByDate;
        }
        return;
    };

    useEffect(() => {
        if (filteredData) {
            const filteredUserIdList = filteredData
                .sort((a, b) => {
                    return isBefore(a.date as Date, b.date as Date) ? -1 : 1;
                })
                .map((item) => item.userNuliaGuid);
            setFilteredLatestDateData(
                latestDateData.filter((dataItem) => {
                    return filteredUserIdList.includes(dataItem.userNuliaGuid);
                })
            );

            const competenceMapByDate = generateMapByDateBasedOnData(filteredData);
            if (competenceMapByDate) {
                setSkillMapByDate(competenceMapByDate);
                setFilteredSkillMapByDate(competenceMapByDate);
            }
        }
    }, [filteredData]);

    useEffect(() => {
        if (data && data.length > 0) {
            setSelectedSkillName(data[0].skillName);
            const competenceMapByDate = generateMapByDateBasedOnData(data);
            if (competenceMapByDate) {
                setSkillMapByDate(competenceMapByDate);
                setFilteredSkillMapByDate(competenceMapByDate);
            }

            const newestDate = data.reduce((a, b) => {
                return a.date > b.date ? a : b;
            }).date;
            newestDateRef.current = newestDate;
            const latestDataItems = data.filter((dataItem) => {
                if (dataItem.date.getTime() === newestDate.getTime()) return true;
                return false;
            });
            setLatestDateData(latestDataItems);
            setFilteredLatestDateData(latestDataItems);
        }
    }, [data]);

    useEffect(() => {
        if (skillData) {
            const departments = new Set<string>();
            const regions = new Set<string>();

            skillData.forEach((dataItem) => {
                if (dataItem.department) departments.add(dataItem.department);
                if (dataItem.region) regions.add(dataItem.region);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'department') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: insightsOrganizationSkillByDepartmentFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'region') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    regions.forEach((region) => {
                        categoryValues.push({
                            key: region,
                            name: region,
                            callback: insightsOrganizationSkillByRegionFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [skillData]);

    useEffect(() => {
        if (filteredSkillMapByDate && filteredSkillMapByDate.size > 0) {
            const assignedScoreLine: IScoreLine = {
                id: 'SW1',
                name: 'Assigned',
                color: theme.palette.status.assigned,
                scores: []
            };

            const userAchievedScoreLine: IScoreLine = {
                id: 'SW2',
                name: 'User Achieved',
                color: '#349FEE',
                scores: []
            };

            const inProgressScoreLine: IScoreLine = {
                id: 'SW2',
                name: 'In Progress',
                color: theme.palette.status.inProgress,
                scores: []
            };

            const attainedScoreLine: IScoreLine = {
                id: 'SW3',
                name: 'Attained',
                color: theme.palette.status.attained,
                scores: []
            };

            const needAttentionScoreLine: IScoreLine = {
                id: 'SW4',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                scores: []
            };

            filteredSkillMapByDate.forEach(function (mapValue, mapKey) {
                let assignedOutcomesPerDateTotal = 0;
                let achievedOutcomesPerDateTotal = 0;
                let inProgressOutcomesPerDateTotal = 0;
                let attainedOutcomesPerDateTotal = 0;
                let needAttentionOutcomesPerDateTotal = 0;

                mapValue.forEach((mapItem) => {
                    assignedOutcomesPerDateTotal += mapItem.assigned;
                    achievedOutcomesPerDateTotal += mapItem.userAchieved;
                    inProgressOutcomesPerDateTotal += mapItem.inProgress;
                    attainedOutcomesPerDateTotal += mapItem.attained;
                    needAttentionOutcomesPerDateTotal += mapItem.needsAttention;
                });

                assignedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: assignedOutcomesPerDateTotal
                });

                userAchievedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: achievedOutcomesPerDateTotal
                });

                inProgressScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: inProgressOutcomesPerDateTotal
                });

                attainedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: attainedOutcomesPerDateTotal
                });

                needAttentionScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: needAttentionOutcomesPerDateTotal
                });

                const allIndividualScoreTimes = [
                    expandDatesToLastYearPerWeek(assignedScoreLine),
                    expandDatesToLastYearPerWeek(userAchievedScoreLine),
                    expandDatesToLastYearPerWeek(inProgressScoreLine),
                    expandDatesToLastYearPerWeek(attainedScoreLine),
                    expandDatesToLastYearPerWeek(needAttentionScoreLine)
                ];

                const scoreLinesPerMonths = insightsScoresPerDateSelector(allIndividualScoreTimes);
                setScoreLines(scoreLinesPerMonths[2]);

                scoreLinesAllOptionsRef.current = scoreLinesPerMonths;
            });
        } else {
            setScoreLines([]);
        }
    }, [filteredSkillMapByDate]);

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        setScoreLines(selectScoreLinePerMonth(scoreLinesAllOptionsRef.current, option));
    };

    const onAnythingChange: any = useCallback(
        (organizationUsersDataNeedToBeFiltered: IInsightsOrganizationSkillVM[], runSearchText?: boolean) => {
            let newFilteredSkills = [...organizationUsersDataNeedToBeFiltered];

            if (runSearchText) {
                newFilteredSkills = instantFilterByText(searchText, newFilteredSkills);
                setFilteredData(newFilteredSkills);
            } else {
                onFilterValueChange(filterCategories, activeFilters, true);
                setFilteredData(newFilteredSkills);
            }
            return newFilteredSkills;
        },
        [
            skillData,
            // @ts-ignore
            searchText,
            // @ts-ignore
            activeFilters,
            filterCategories
        ]
    );

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<IInsightsOrganizationSkillVM>({
        data: data,
        dataSerachablePropertyName: 'userName',
        setDataCallback: setFilteredData,
        onChangeCallback: onAnythingChange
    });

    useEffect(() => {
        if (skillData) {
            setData(skillData);
            setFilteredData(skillData);
            const newestDate = skillData.reduce(
                (a, b) => {
                    return a.date > b.date ? a : b;
                },
                {
                    date: new Date(1970, 1, 1)
                }
            ).date;
            newestDateRef.current = newestDate;
            // const latestDataItems = skillData.filter((dataItem) => {
            //     if (dataItem.date.getTime() === newestDate.getTime()) return true;
            //     return false;
            // });
        }
    }, [skillData]);

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => {
        setActiveFilters(activeFilters);
        let newFilteredData = [...data];
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && activeFilters.includes(filterCategoryValue.key)) {
                        newFilteredData = newFilteredData.filter((dataItem) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    dataItem,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredData, true);
    };

    const insightsOrganizationalSkillContext: IInsightsOrganizationalSkillContext = {
        data: filteredData,
        latestDateData: filteredLatestDateData,
        scoreLines,
        searchText,
        setSearchText,
        changeScoreLinesInterval,
        filterCategories,
        onFilterValueChange,
        isSkillDataLoading
    };

    return (
        <InsightsOrganizationalSkillContext.Provider value={insightsOrganizationalSkillContext}>
            {children}
        </InsightsOrganizationalSkillContext.Provider>
    );
};

export const useInsightsOrganizationalSkillStateValue: () => IInsightsOrganizationalSkillContext = () =>
    useContext(InsightsOrganizationalSkillContext);
