import { TableCell, Box, Typography } from '@mui/material';
import styled from '../ui/theme/styled';
import Paper from '@mui/material/Paper';
import { TablePagination, Tabs, Tab } from '@mui/material';
import { keyframes } from '@mui/system';

export const pulseKeyFrame = keyframes`
    from { transform: scale(1); }
    to { transform: scale(1.2); }
`;

export const LabelTypography = styled('span')(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '20px',
    color: 'rgba(0,0,0,0.8)',
    letterSpacing: '0.416px'
}));

export const ShepherdHeaderTypography = styled(Typography)({
    color: '#000000CC'
});

export const ShepherdList = styled('ul')({
    '& li:not(:last-child)': {
        marginBottom: '10px'
    }
});

export const ShepherdListItem = styled('li')({});

export const ShepherdVerticalCenteredBox = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
});

export const ShepherdContentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    color: theme.palette.common.black
}));

export const ShepherdTextBox = styled('div')(({ theme }) => ({
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Open Sans',
    color: theme.palette.common.black
}));

export const ShepherdImageBox = styled('div')(({ theme }) => ({
    minWidth: '100px'
}));

export const StyledTableCell = styled(TableCell)({
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.64)',
    '.MuiTableCell-root': {
        padding: 1
    }
});

export const StyledHeaderTableCell = styled(TableCell)({
    fontFamily: 'Ubuntu',
    fontSize: 14,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.8)',
    letterSpacing: '0.1px'
});

export const StyledMultipleCheckboxHeaderTableCell = styled(StyledHeaderTableCell)({
    paddingLeft: '16px'
});

export const TablePaper = styled(Paper)({
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)'
});

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    '.MuiTablePagination-selectIcon': {
        top: '6px !important'
    },
    '.MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: '20px',
        color: 'rgba(0, 0, 0, 0.4)'
    },
    '.MuiTablePagination-select': {
        backgroundColor: 'unset !important',
        fontSize: '12px !important',
        lineHeight: 20,
        letterSpacing: '0.5px',
        color: 'rgba(0, 0, 0, 0.64)'
    },
    '.MuiTablePagination-displayedRows': {
        letterSpacing: '0.5px',
        color: 'rgba(0, 0, 0, 0.4)',
        fontSize: '12px !important',
        fontFamily: 'Open Sans'
    },
    '.MuiTablePagination-toolbar': {
        [theme.breakpoints.down('md')]: {
            disply: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
        }
    }
}));

export const StyledTab = styled(Tab, {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ isActive, theme }) => ({
    fontSize: '20px',
    fontFamily: 'Ubuntu',
    lineHeight: '24px',
    letterSpacing: '0.25px',
    color: isActive ? theme.palette.primary.main : theme.palette.text.secondary,
    '&.MuiTab-root': {
        minWidth: 'unset',
        padding: 0,
        position: 'relative',
        top: '-3px',
        [theme.breakpoints.down('lg')]: {
            textTransform: 'unset'
        }
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        letterSpacing: '0.1px'
    }
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: '45px',
    marginTop: '12px',
    marginLeft: '20px',
    '& .MuiTabs-indicator': {
        height: '4px',
        borderRadius: '4px'
    },
    '& .MuiTabs-flexContainer': {
        display: 'flex',
        gap: '41px'
    },
    '&.MuiTabs-root': {
        marginTop: 0,
        marginLeft: 0
    }
}));

export const CloseDrawerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 10px 0 10px',
    cursor: 'pointer',
    borderRadius: '12px'
}));

export const CloseDrawerActionWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    '&:hover': {
        backgroundColor: theme.palette.background.default,
        borderRadius: '16px'
    }
}));

export const FilterDrawerBox = styled(Box)({
    minWidth: '250px',
    width: '300px',
    padding: '20px',
    '.MuiAccordion-root': { background: '#FFF !important' },
    '.MuiAccordionSummary-content': { background: '#FFF !important' }
});

export const FilterIconSpan = styled('span')(({ theme }) => ({
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    marginRight: '40px',
    '&:hover': {
        backgroundColor: theme.palette.background.default
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        marginRight: '12px'
    }
}));

const StatusCircle = styled('div')({
    width: '12px',
    height: '12px',
    borderRadius: '6px'
});

export const IncreaseStatusCircle = styled(StatusCircle)(({ theme }) => ({
    backgroundColor: theme.palette.progressIndicators.increase
}));

export const DecreaseStatusCircle = styled(StatusCircle)(({ theme }) => ({
    backgroundColor: theme.palette.progressIndicators.decrease
}));

export const FlatStatusCircle = styled(StatusCircle)(({ theme }) => ({
    backgroundColor: theme.palette.progressIndicators.flat
}));

export const UnassignedStatusCircle = styled(StatusCircle)(({ theme }) => ({
    backgroundColor: theme.palette.progressIndicators.unassigned
}));

export const WarningStatusCircle = styled(StatusCircle)(({ theme }) => ({
    backgroundColor: 'rgba(176, 203, 62, 1)'
}));

export const InProgressStatusCircle = styled(StatusCircle)(({ theme }) => ({
    backgroundColor: theme.palette.progressIndicators.inProgress
}));

export const LongDottedTextSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'width'
})<{ width?: string }>(({ width, theme }) => ({
    display: 'inline-block',
    width,
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    fontSize: '18px',
    paddingBottom: '2px',
    opacity: '0.88',
    color: theme.palette.common.black
}));
