import { IScoreDot, IScoreLine } from '../interfaces/IScoreLine';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import { format, isAfter } from 'date-fns';

export const mapInsightsGraphScoreDates = (scores: IScoreDot[]) => {
    return scores.map((scoreItem) => format(scoreItem.date as Date, 'MM/d/yy'));
};

export const insightsScoresPerDateSelector: (scoreLines: IScoreLine[]) => IScoreLine[][] = (scoreLines) => {
    let nowDate = new Date();
    let oneMonthBefore = new Date();
    oneMonthBefore.setMonth(nowDate.getMonth() - 1);
    let twoMonthBefore = new Date();
    twoMonthBefore.setMonth(nowDate.getMonth() - 2);
    let threeMonthBefore = new Date();
    threeMonthBefore.setMonth(nowDate.getMonth() - 3);
    let sixMonthBefore = new Date();
    sixMonthBefore.setMonth(nowDate.getMonth() - 6);
    let nineMonthBefore = new Date();
    nineMonthBefore.setMonth(nowDate.getMonth() - 9);

    const oneMonthsArray: IScoreLine[] = [];
    const twoMonthsArray: IScoreLine[] = [];
    const threeMonthsArray: IScoreLine[] = [];
    const sixMonthsArray: IScoreLine[] = [];
    const nineMonthsArray: IScoreLine[] = [];

    scoreLines.forEach((scoreTime) => {
        const scoresOneMonth = scoreTime.scores.filter((scoreDot) => {
            return isAfter(scoreDot.date as Date, oneMonthBefore);
        });
        const scoresTwoMonth = scoreTime.scores.filter((scoreDot) => {
            return isAfter(scoreDot.date as Date, twoMonthBefore);
        });
        const scoresThreeMonth = scoreTime.scores.filter((scoreDot) => {
            return isAfter(scoreDot.date as Date, threeMonthBefore);
        });
        const scoresSixMonth = scoreTime.scores.filter((scoreDot) => {
            return isAfter(scoreDot.date as Date, sixMonthBefore);
        });
        const scoresNineMonth = scoreTime.scores.filter((scoreDot) => {
            return isAfter(scoreDot.date as Date, nineMonthBefore);
        });

        const sc1 = { ...scoreTime };
        sc1.scores = scoresOneMonth;
        oneMonthsArray.push(sc1);

        const sc2 = { ...scoreTime };
        sc2.scores = scoresTwoMonth;
        twoMonthsArray.push(sc2);

        const sc3 = { ...scoreTime };
        sc3.scores = scoresThreeMonth;
        threeMonthsArray.push(sc3);

        const sc6 = { ...scoreTime };
        sc6.scores = scoresSixMonth;
        sixMonthsArray.push(sc6);

        const sc9 = { ...scoreTime };
        sc9.scores = scoresNineMonth;
        nineMonthsArray.push(sc9);
    });

    return [oneMonthsArray, twoMonthsArray, threeMonthsArray, sixMonthsArray, nineMonthsArray, scoreLines];
};

export const selectScoreLinePerMonth = (
    allScoreLinesPerMonth: IScoreLine[][],
    selectedMonthOption: EGraphCardSelect
) => {
    switch (selectedMonthOption) {
        case EGraphCardSelect.MONTH_1:
            return allScoreLinesPerMonth[0];
        case EGraphCardSelect.MONTH_2:
            return allScoreLinesPerMonth[1];
        case EGraphCardSelect.MONTH_3:
            return allScoreLinesPerMonth[2];
        case EGraphCardSelect.MONTH_6:
            return allScoreLinesPerMonth[3];
        case EGraphCardSelect.MONTH_9:
            return allScoreLinesPerMonth[4];
        case EGraphCardSelect.YEAR_WITH_WEEKS:
            return allScoreLinesPerMonth[5];
    }
};
