import { ChangeEvent, FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
    BadgeActionsBox,
    BadgeImg,
    BadgeStateWrapper,
    ClaimBox,
    DownloadBadgeBox,
    ImageBox,
    ShareBadgeBox,
    StateBox
} from './Style';
import GraphCard from '../../../../../pages/insights/components/graph/graph-card/GraphCard';
import Table from '../../../../../ui/general/table/Table';
import { EGraphCardSelect } from '../../../../../interfaces/enums/EGraphCardSelect';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import { useInsightsPersonalBadgesStateValue } from '../../../../../contexts/InsightsPersonalBadgesContext';
import { IInsightsPersonalBadgeVM } from '../../../../../interfaces/views/IInsightsPersonalBadgeVM';
import { EBadgeLevel } from '../../../../../interfaces/enums/EBadgeLevel';
import PrimaryButton from '../../../../../ui/buttons/primary-button/PrimaryButton';
import { ESkillLevel } from '../../../../../interfaces/enums/ESkillLevel';
import Tooltip from '../../../../../ui/general/tooltip/Tooltip';
import DownloadBadgeIcon from '../../../../../assets/icons/DownloadBadgeIcon';
import ShareBadgeIcon from '../../../../../assets/icons/ShareBadgeIcon';
import { createUrl } from '../../../../../utils/createUrl';
import {
    FullWidthBox,
    RootBox,
    SearchInputWrapper,
    StyledSearchInput,
    TableItemCountBox,
    TableItemCountTypography
} from '../../../Style';
import useInsightsTimelineOption from '../../../../../hooks/useInsightsTimelineOption';
import ShareBadgeModal from '../../../../../ui/modals/share-badge-modal/ShareBadgeModal';
import { formatDate } from '../../../../../utils/dateUtil';
import { ELinkedinRedirectUrl } from '../../../../../interfaces/enums/ELinkedinRedirectUrl';
import { EToastSeverity, useToastContextStateValue } from '../../../../../contexts/ToastContext';
import { routes } from '../../../../../pages/routes';
import { EInsightsMode } from '../../../../../interfaces/enums/EInsightsMode';
import { useInsightsStateValue } from '../../../../../contexts/InsightsContext';
import fetchImageErrorCallback from '../../../../../utils/fetchImageErrorCallback';
import { EShowcaseBadgeLevel } from '../../../../../interfaces/enums/EShowcaseBadgeLevel';
import { mapInsightsGraphScoreDates } from '../../../../../utils/insightsGraphDataSelector';

interface ITableHeader {
    badgeIcon: string;
    title: string;
    state: string;
    claimedDate: string;
    verifiedDate: string;
}

const PersonalBadgesTab: FC<PropsWithChildren> = () => {
    const { t } = useTranslation();
    const {
        data,
        scoreLines,
        changeScoreLinesInterval,
        claimClickHandler,
        isPostClaimMasterBadgeLoading,
        isPostClaimProducerBadgeLoading,
        handleDownloadBadgeClick,
        isLoading,
        searchText,
        setSearchText,
        isErrorFetchBadgeData
    } = useInsightsPersonalBadgesStateValue();
    const { activeTimelineOption, handleTimelineChange } = useInsightsTimelineOption({
        changedTimelineOptionCallback: changeScoreLinesInterval,
        initialTimelineOptionValue: EGraphCardSelect.MONTH_3
    });
    const [isShareBadgeModalOpen, setShareBadgeModalOpen] = useState<boolean>(false);
    const handleShareBadgeModalClose = useCallback(() => {
        setShareBadgeItem(undefined);
        setShareBadgeModalOpen(false);
    }, []);
    const handleShareBadgeModalOpen = useCallback((item: IInsightsPersonalBadgeVM) => {
        setShareBadgeItem(item);
        setShareBadgeModalOpen(true);
    }, []);
    const [shareBadgeItem, setShareBadgeItem] = useState<IInsightsPersonalBadgeVM | undefined>(undefined);
    const [searchParams] = useSearchParams();
    const { setToastMessage } = useToastContextStateValue();
    const location = useLocation();
    const { mode } = useInsightsStateValue();

    useEffect(() => {
        const successShareBadge = searchParams.get('successShareBadge');
        if (successShareBadge === '1' && location.state?.from === routes.LINKEDIN)
            setToastMessage({
                isOpen: true,
                message: t('badges.shareBadgeSuccess'),
                severity: EToastSeverity.SUCCESS
            });
    }, [searchParams]);

    const shareBadgeNowHandler = (obj: IInsightsPersonalBadgeVM) => {
        let levelValue = 0;
        if (obj.level === EShowcaseBadgeLevel.MASTER) levelValue = 3;
        else levelValue = 2;
        if (levelValue !== 0) {
            window.open(
                `https://www.linkedin.com/oauth/v2/authorization?response_type=code` +
                    `&client_id=${encodeURIComponent(process.env.REACT_APP_LINKEDIN_AUTH_CLIENT_ID as string)}` +
                    `&state=${levelValue}-${obj.outcomeId}-${ELinkedinRedirectUrl.INSIGHTS}` +
                    `&redirect_uri=${encodeURIComponent(`${window.location.origin}/linkedin`)}` +
                    `&scope=${encodeURIComponent('r_liteprofile w_member_social')}`,
                '_self'
            );
        }
    };

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'badgeIcon',
            label: '',
            minWidth: '112px',
            height: '130px',
            customRender: (value: any, obj: IInsightsPersonalBadgeVM) => {
                return (
                    <ImageBox>
                        <BadgeImg src={createUrl(obj.image)?.generatedUrl} onError={fetchImageErrorCallback} />
                        {/* {level !== ESkillLevel.USER && obj.state === EBadgeLevel.CLAIMED && ( */}
                        {mode === EInsightsMode.STANDARD && obj.state === EBadgeLevel.CLAIMED && (
                            <BadgeActionsBox>
                                <Tooltip title={t('tooltips.downloadBadge')}>
                                    <DownloadBadgeBox onClick={() => handleDownloadBadgeClick(obj)}>
                                        <DownloadBadgeIcon />
                                    </DownloadBadgeBox>
                                </Tooltip>
                                <Tooltip title={t('tooltips.shareBadge')}>
                                    <ShareBadgeBox onClick={() => handleShareBadgeModalOpen(obj)}>
                                        <ShareBadgeIcon />
                                    </ShareBadgeBox>
                                </Tooltip>
                            </BadgeActionsBox>
                        )}
                    </ImageBox>
                );
            },
            disableSort: true
        },
        {
            id: 'title',
            label: 'Badge Name'
        },
        {
            id: 'state',
            label: 'Status',
            minWidth: '130px',
            align: 'center',
            customRender: (value: EBadgeLevel, obj: IInsightsPersonalBadgeVM) => {
                let formattedValue = value.toString();
                if (value === EBadgeLevel.NEEDS_ATTENTION) {
                    formattedValue = 'Need Attention';
                }
                return (
                    <BadgeStateWrapper>
                        <StateBox>{formattedValue}</StateBox>
                        <ClaimBox>
                            {value === EBadgeLevel.EARNED && (
                                <PrimaryButton
                                    title='Claim Now'
                                    disabled={isPostClaimMasterBadgeLoading || isPostClaimProducerBadgeLoading}
                                    clickHandler={() => claimClickHandler(obj.outcomeId, obj.level as EShowcaseBadgeLevel)}
                                />
                            )}
                            {mode === EInsightsMode.STANDARD && value === EBadgeLevel.CLAIMED && (
                                <PrimaryButton
                                    title='Share Now'
                                    // TODO Change this when backend provides level
                                    clickHandler={() => shareBadgeNowHandler(obj)}
                                />
                            )}
                        </ClaimBox>
                    </BadgeStateWrapper>
                );
            }
        },
        {
            id: 'claimedDate',
            label: 'Claimed Date',
            align: 'center',
            minWidth: '140px',
            customRender: (value) => {
                try {
                    if (value) return <div>{formatDate(value)}</div>;
                } catch (e) {
                    return 'N/A';
                }
                return 'N/A';
            }
        },
        {
            id: 'verifiedDate',
            label: 'Verified Date',
            align: 'center',
            minWidth: '140px',
            customRender: (value) => {
                try {
                    if (value) return <div>{formatDate(value)}</div>;
                } catch (e) {
                    return 'N/A';
                }
                return 'N/A';
            }
        }
    ];

    const scoreLinesValuesMemo = useMemo(() => {
        if (isLoading || !scoreLines) return undefined;
        return scoreLines;
    }, [scoreLines, isLoading]);

    const xAxisMemo = useMemo(() => {
        if (isLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            return mapInsightsGraphScoreDates(scoreLines[0].scores);
        }
        return [];
    }, [scoreLines, activeTimelineOption, isLoading]);

    const customToolbar = useMemo(() => {
        return (
            <TableItemCountBox>
                {data && (
                    <TableItemCountTypography variant='caption'>{`${data.length} Outcomes`}</TableItemCountTypography>
                )}
            </TableItemCountBox>
        );
    }, [data]);

    return (
        <RootBox>
            <GraphCard
                scoreLines={scoreLinesValuesMemo}
                xAxis={xAxisMemo}
                handleTimelineChange={handleTimelineChange}
                translations={{ graphCardTitle: t('insights.personal.badges.graphCardTitle') }}
                isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                isLoading={isLoading || scoreLinesValuesMemo === undefined}
            />
            <SearchInputWrapper className='search-box'>
                <StyledSearchInput
                    placeholder='Search Outcomes'
                    value={searchText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (setSearchText) setSearchText(e.target.value);
                    }}
                />
            </SearchInputWrapper>
            <FullWidthBox>
                <Table<IInsightsPersonalBadgeVM, ITableHeader>
                    headCells={headCells}
                    data={data}
                    propertyKeys={headCells.map((headCell) => {
                        return headCell.id;
                    })}
                    tableTitlePlural=''
                    isFilterControlVisible={false}
                    isFilterDrawerOpen={false}
                    isLoading={isLoading}
                    isError={isErrorFetchBadgeData}
                    customToolbar={customToolbar}
                    initialOrderBy='title'
                    customMediaColumnName='badgeIcon'
                />
            </FullWidthBox>
            {isShareBadgeModalOpen && shareBadgeItem && (
                <ShareBadgeModal
                    handleClose={handleShareBadgeModalClose}
                    badgeImage={shareBadgeItem.image}
                    // TODO should be shareBadgeItem.level
                    level={ESkillLevel.PRODUCER}
                    // TODO should be shareBadgeItem.outcomeId
                    outcomeId={shareBadgeItem.id}
                    userBadgeUrl={shareBadgeItem.userBadgeUrl}
                    calledFrom={ELinkedinRedirectUrl.INSIGHTS}
                />
            )}
        </RootBox>
    );
};

export default PersonalBadgesTab;
