import { TableFooter, TableRow } from '@mui/material';
import { PaginationBox, PaginationTable } from './Style';
import { StyledTablePagination } from '../../../css/CommonComponents';

interface IProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const TablePagination = (props: IProps) => {
    const { count, page, rowsPerPage, onPageChange, onRowsPerPageChange } = props;
    return (
        <PaginationBox>
            <PaginationTable>
                <TableFooter>
                    <TableRow>
                        <StyledTablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                        />
                    </TableRow>
                </TableFooter>
            </PaginationTable>
        </PaginationBox>
    );
};

export default TablePagination;
