import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { Paper } from '@material-ui/core';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
});

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: 16,
    borderRadius: theme.borderRadius?.outside?.small
}));

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    width: 1080,
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        flexDirection: 'column'
    }
}));

export const MainContentBox = styled('div')({
    flex: 11
});

export const ContentHeaderBox = styled('div')({
    marginBottom: 16
});

export const SideBox = styled('div')(({ theme }) => ({
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    gap: 32,
    [theme.breakpoints.down('md')]: {
        padding: 0
    }
}));

export const SkillsStatusOverallWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled'
})<{
    isDisabled: boolean;
}>(({ isDisabled }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    paddingBottom: 24,
    flex: 1,
    pointerEvents: isDisabled ? 'none' : 'unset',
    opacity: isDisabled ? 0.4 : 1,
    '@media only screen and (max-width: 880px)': {
        '& .number-status-box-root': {
            flexBasis: '30%'
        }
    },
    '@media only screen and (max-width: 710px)': {
        '& .number-status-box-root': {
            flexBasis: '22%'
        }
    },
    '@media only screen and (max-width: 410px)': {
        '& .number-status-box-root': {
            flexBasis: '12%'
        }
    }
}));

export const TitleBox = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main
}));

export const FilterIconSpan = styled('span')(({ theme }) => ({
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    '&:hover': {
        backgroundColor: theme.palette.background.default
    }
}));

export const TitleSpan = styled('span')(({ theme }) => ({
    paddingRight: 6,
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 8
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '14px'
    }
}));

export const FilterBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDisabled'
})<{ isDisabled: boolean }>(({ isDisabled, theme }) => ({
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    pointerEvents: isDisabled ? 'none' : 'unset',
    opacity: isDisabled ? 0.4 : 1,
    marginRight: 6,
    [theme.breakpoints.down('md')]: {
        gap: 2
    }
}));

export const HeaderWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16
});

export const FilterLabel = styled('span')({
    letterSpacing: '2px',
    textTransform: 'uppercase',
    fontFamily: 'Ubuntu',
    lineHeight: '16px',
    fontSize: '12px'
});

export const FilterSelect = styled(Select)(({ theme }) => ({
    width: 180,
    cursor: 'pointer',
    backgroundColor: 'rgba(250, 250, 250, 1)',
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    [theme.breakpoints.down('lg')]: {
        width: 'unset'
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiSelect-select': {
            fontSize: '12px !important',
            height: '24px !important'
        },
        '& svg': {
            top: '9px'
        }
    }
}));

export const OrderSpan = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 4,
    borderRadius: 20,
    '&:hover': {
        backgroundColor: theme.palette.background.default
    }
}));

export const OutcomesCountBox = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
        fontSize: '12px'
    }
}));

export const OutcomesListWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms',
    opacity: 1,
    minHeight: 140,
    paddingTop: 16
});

export const OutcomeCardWrapper = styled('div')({
    width: '100%'
});

export const PaginationBox = styled('div')({
    width: '100%'
});

export const PaginationTable = styled('table')({
    width: '100%',
    '& td': {
        borderBottom: 0
    }
});
