import { styled } from '@mui/material/styles';

export const PaginationBox = styled('div')({
    width: '100%'
});

export const PaginationTable = styled('table')({
    width: '100%',
    '& td': {
        borderBottom: 0
    }
});
