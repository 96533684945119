import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import { useState } from 'react';

interface IUseInsightsTimelineOption {
    handleTimelineChange: (value: string | string[]) => void;
    activeTimelineOption: EGraphCardSelect;
}

interface IUseInsightsTimelineOptionProps {
    initialTimelineOptionValue: EGraphCardSelect;
    changedTimelineOptionCallback: (value: EGraphCardSelect) => void;
}

const useInsightsTimelineOption: (props: IUseInsightsTimelineOptionProps) => IUseInsightsTimelineOption = (props) => {
    const { initialTimelineOptionValue, changedTimelineOptionCallback } = props;

    const [timelineOptionValue, setTimelineOptionValue] = useState<EGraphCardSelect>(
        initialTimelineOptionValue || EGraphCardSelect.MONTH_1
    );

    const handleTimelineChange = (value: string | string[]) => {
        changedTimelineOptionCallback(value as EGraphCardSelect);
        setTimelineOptionValue(value as EGraphCardSelect);
    };

    return {
        activeTimelineOption: timelineOptionValue,
        handleTimelineChange
    };
};

export default useInsightsTimelineOption;
