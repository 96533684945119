import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef } from 'react';
import { isBefore } from 'date-fns';
import { useTheme } from '@mui/system';
import { useGetInsightsOrganizationOutcomesQuery } from '../services/InsightsQueryService';
import { IScoreLine } from '../interfaces/IScoreLine';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import useFilterSearch from '../hooks/useFilterSearch';
import { IInsightsOrganizationOutcomeVM } from '../interfaces/views/IInsightsOrganizationOutcomeVM';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { insightsScoresPerDateSelector, selectScoreLinePerMonth } from '../utils/insightsGraphDataSelector';

export interface IInsightsOrganizationOutcomesContext {
    data: any[];
    scoreLines?: IScoreLine[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    outcomesInnerPage: EOutcomesInnerPageState;
    setOutcomesInnerPage: (page: EOutcomesInnerPageState) => void;
    isOutcomeDataLoading: boolean;
    latestDateData: IInsightsOrganizationOutcomeVM[];
}

export const InsightsOrganizationOutcomesContext = createContext<IInsightsOrganizationOutcomesContext>(
    {} as IInsightsOrganizationOutcomesContext
);

export enum EOutcomesInnerPageState {
    MAIN = 'Main',
    DETAILS = 'Details'
}

export const InsightsOrganizationalOutcomesProvider: FC<PropsWithChildren> = ({ children }) => {
    const [data, setData] = useState<IInsightsOrganizationOutcomeVM[]>([]);
    const [filteredData, setFilteredData] = useState<IInsightsOrganizationOutcomeVM[]>([]);
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const { data: fetchedOutcomeData, isLoading: isOutcomeDataLoading } = useGetInsightsOrganizationOutcomesQuery();
    const [_, setOutcomesMapByDate] = useState<Map<string, IInsightsOrganizationOutcomeVM[]>>(new Map());
    const [filteredUserCompetenceMapByDate, setFilteredUserCompetenceMapByDate] = useState<
        Map<string, IInsightsOrganizationOutcomeVM[]>
    >(new Map());
    const [outcomesInnerPage, setOutcomesInnerPage] = useState<EOutcomesInnerPageState>(EOutcomesInnerPageState.MAIN);
    const [latestDateData, setLatestDateData] = useState<IInsightsOrganizationOutcomeVM[]>([]);
    const [filteredLatestDateData, setFilteredLatestDateData] = useState<IInsightsOrganizationOutcomeVM[]>([]);
    const newestDateRef = useRef<Date>();
    const theme = useTheme();

    const generateMapByDateBasedOnData: (
        data: IInsightsOrganizationOutcomeVM[]
    ) => Map<string, IInsightsOrganizationOutcomeVM[]> | undefined = (data) => {
        if (data) {
            const competenceMapByDate = new Map();
            data.forEach((item) => {
                if (item.date && competenceMapByDate.has(item.date.toDateString())) {
                    competenceMapByDate.set(item.date.toDateString(), [
                        ...competenceMapByDate.get(item.date.toDateString()),
                        item
                    ]);
                } else {
                    competenceMapByDate.set(item.date.toDateString(), [item]);
                }
            });
            return competenceMapByDate;
        }
        return;
    };

    useEffect(() => {
        if (filteredData) {
            const filteredOutcomeIdList = filteredData.map((item) => item.outcomeId);
            setFilteredLatestDateData(
                latestDateData.filter((dataItem) => {
                    return filteredOutcomeIdList.includes(dataItem.outcomeId);
                })
            );
            const competenceMapByDate = generateMapByDateBasedOnData(
                filteredData.sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
            );
            if (competenceMapByDate) {
                setFilteredUserCompetenceMapByDate(competenceMapByDate);
            }
        }
    }, [filteredData]);

    useEffect(() => {
        if (fetchedOutcomeData) {
            const mapByDate = new Map();
            fetchedOutcomeData.forEach((item) => {
                if (item.date && mapByDate.has(item.date.toDateString())) {
                    mapByDate.set(item.date.toDateString(), [...mapByDate.get(item.date.toDateString()), item]);
                } else {
                    mapByDate.set(item.date.toDateString(), [item]);
                }
            });
            setOutcomesMapByDate(mapByDate);
            const newestDate = fetchedOutcomeData.reduce(
                (a, b) => {
                    return a.date > b.date ? a : b;
                },
                {
                    date: new Date(1970, 1, 1)
                }
            ).date;
            newestDateRef.current = newestDate;
            const latestDataItems = fetchedOutcomeData.filter((dataItem) => {
                if (dataItem.date.getTime() === newestDate.getTime()) return true;
                return false;
            });
            setLatestDateData(latestDataItems);
            setFilteredLatestDateData(latestDataItems);
        }
    }, [fetchedOutcomeData]);

    useEffect(() => {
        if (filteredUserCompetenceMapByDate && filteredUserCompetenceMapByDate.size > 0) {
            const assignedScoreLine: IScoreLine = {
                id: 'W1',
                name: 'Assigned',
                color: theme.palette.status.assigned,
                scores: []
            };

            const achievedScoreLine: IScoreLine = {
                id: 'W2',
                name: 'User Achieved',
                color: '#349FEE',
                scores: []
            };

            const inProgressScoreLine: IScoreLine = {
                id: 'W3',
                name: 'In Progress',
                color: theme.palette.status.inProgress,
                scores: []
            };

            const attainedScoreLine: IScoreLine = {
                id: 'W4',
                name: 'Attained',
                color: theme.palette.status.attained,
                scores: []
            };

            const needAttentionScoreLine: IScoreLine = {
                id: 'W5',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                legendColor: '#ED8000',
                scores: []
            };

            filteredUserCompetenceMapByDate.forEach(function (mapValue, mapKey) {
                let assignedOutcomesPerDateTotal = 0;
                let achievedOutcomesPerDateTotal = 0;
                let inProgressOutcomesPerDateTotal = 0;
                let attainedOutcomesPerDateTotal = 0;
                let needAttentionOutcomesPerDateTotal = 0;

                mapValue.forEach((mapItem) => {
                    assignedOutcomesPerDateTotal += mapItem.assigned;
                    achievedOutcomesPerDateTotal += mapItem.userAchieved;
                    inProgressOutcomesPerDateTotal += mapItem.inProgress;
                    attainedOutcomesPerDateTotal += mapItem.attained;
                    needAttentionOutcomesPerDateTotal += mapItem.needAttention;
                });

                assignedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: assignedOutcomesPerDateTotal
                });

                achievedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: achievedOutcomesPerDateTotal
                });

                inProgressScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: inProgressOutcomesPerDateTotal
                });

                attainedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: attainedOutcomesPerDateTotal
                });

                needAttentionScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: needAttentionOutcomesPerDateTotal
                });

                const allIndividualScoreTimes = [
                    expandDatesToLastYearPerWeek(assignedScoreLine),
                    expandDatesToLastYearPerWeek(achievedScoreLine),
                    expandDatesToLastYearPerWeek(inProgressScoreLine),
                    expandDatesToLastYearPerWeek(attainedScoreLine),
                    expandDatesToLastYearPerWeek(needAttentionScoreLine)
                ];
                const scoreLinesPerMonths = insightsScoresPerDateSelector(allIndividualScoreTimes);
                setScoreLines(scoreLinesPerMonths[2]);

                scoreLinesAllOptionsRef.current = scoreLinesPerMonths;
            });
        } else {
            setScoreLines([]);
        }
    }, [filteredUserCompetenceMapByDate]);

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        setScoreLines(selectScoreLinePerMonth(scoreLinesAllOptionsRef.current, option));
    };

    const { searchText, setSearchText } = useFilterSearch<IInsightsOrganizationOutcomeVM>({
        data: data,
        dataSerachablePropertyName: 'outcomeName',
        setDataCallback: setFilteredData
    });

    useEffect(() => {
        if (fetchedOutcomeData) {
            setData(fetchedOutcomeData);
            setFilteredData(fetchedOutcomeData);
        }
    }, [fetchedOutcomeData]);

    const insightsOrganizationOutcomesContext: IInsightsOrganizationOutcomesContext = {
        data: filteredData,
        scoreLines,
        searchText,
        setSearchText,
        changeScoreLinesInterval,
        outcomesInnerPage,
        setOutcomesInnerPage,
        isOutcomeDataLoading,
        latestDateData: filteredLatestDateData
    };

    return (
        <InsightsOrganizationOutcomesContext.Provider value={insightsOrganizationOutcomesContext}>
            {children}
        </InsightsOrganizationOutcomesContext.Provider>
    );
};

export const useInsightsOrganizationOutcomesStateValue: () => IInsightsOrganizationOutcomesContext = () =>
    useContext(InsightsOrganizationOutcomesContext);
