import { ETenantHistoryStatus } from '../interfaces/enums/ETenantHistoryStatus';

export const convertHistoryStatusToStringValues: (historyItemStatus: ETenantHistoryStatus) => string = (
    historyItemStatus
) => {
    switch (historyItemStatus) {
        case ETenantHistoryStatus.IN_PROGRESS:
            return 'In Progress';
        default:
            return historyItemStatus.toString();
    }
};
