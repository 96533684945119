import { IAllOutcomeDTO } from '../../interfaces/dtos/IAllOutcomeDTO';
import { EFilterOperator, IFilterCategories } from '../../ui/filters/filters/Filters';
import { IUserSkillDTO } from '../../interfaces/dtos/IUserSkillDTO';
import { ESkillLevel } from '../../interfaces/enums/ESkillLevel';
import { IUserSkillVM } from '../../interfaces/views/IUserSkillVM';
import { IUserOutcomeVM } from '../../interfaces/views/IUserOutcomeVM';
import { ILicenseVM } from '../../interfaces/views/ILicenseVM';
import { IAdUserVM } from '../../interfaces/views/IAdUserVM';
import { IRoleVM } from '../../interfaces/views/IRoleVM';
import { IAdminOutcomeVM } from '../../interfaces/views/IAdminOutcomeVM';
import { IUserAssignOutcomeVM } from '../../interfaces/views/IUserAssignOutcomeVM';
import { ITenantHistoryVM } from '../../interfaces/views/ITenantHistoryVM';
import { IAdminHistoryDetailsVM } from '../../interfaces/views/IAdminHistoryDetailsVM';
import { convertHistoryTypeToStringValues } from '../../utils/convertHistoryTypeToStringValues';
import { IInsightsOrganizationUsersCompetenceVM } from '../../interfaces/views/IInsightsOrganizationUsersCompetenceVM';
import { IInsightsOrganizationBadgesVM } from '../../interfaces/views/IInsightsOrganizationBadgesVM';
import { IInsightsOrganizationEngagementVM } from '../../interfaces/views/IInsightsOrganizationEngagementVM';
import { IInsightsOrganizationOutcomesUserVM } from '../../interfaces/views/IInsightsOganizationOutcomesUserVM';
import { EUserRoleLogical } from '../../interfaces/enums/EUserRoleLogical';
import { IInsightsOrganizationSkillVM } from '../../interfaces/IInsightsOrganizationSkillVM';
import { convertHistoryStatusToStringValues } from '../../utils/convertHistoryStatusToStringValues';

export enum ESkillSort {
    NAME = 'name',
    SCORE = 'score',
    ASSIGNED_DATE = 'assignedDate',
    WEEKS_IN_PROGRESS = 'weeksInProgress'
}

export enum EOutcomeSort {
    NAME = 'name',
    SCORE = 'score',
    ASSIGNED_DATE = 'assignedDate',
    WEEKS_IN_PROGRESS = 'weeksInProgress'
}

export enum EAdminOutcomeSort {
    NAME = 'name',
    NUMBER_OF_USERS = '# Users'
}

export enum EOrderDirection {
    ASC = 'ascending',
    DESC = 'desceding'
}
export interface ISkillSortOrder {
    sortBy: ESkillSort;
    direction: EOrderDirection;
}
export interface IOutcomeSortOrder {
    sortBy: EOutcomeSort;
    direction: EOrderDirection;
}

export interface IAdminOutcomeSortOrder {
    sortBy: EAdminOutcomeSort;
    direction: EOrderDirection;
}

export const appFilterCallback = (obj: IAllOutcomeDTO | IUserSkillDTO, appName: string) => {
    return obj.apps.find((app) => app.name === appName);
};

export const appFilterAdminOutcomesCallback = (obj: IAdminOutcomeVM, appName: string) => {
    return obj.outcome.apps.find((app) => app.name === appName);
};

export const categoryFilterCallback = (
    obj: IAllOutcomeDTO | IUserSkillDTO,
    categoryName: string,
    categoryKey: string
) => {
    return obj.categories.includes(categoryKey);
};

export const adminOutcomesCategoryFilterCallback = (
    obj: IAdminOutcomeVM,
    categoryName: string,
    categoryKey: string
) => {
    return obj.outcome.categories.includes(categoryKey);
};

const statusFilterCallback = (obj: IUserOutcomeVM | IUserSkillVM, categoryName: string) => {
    switch (categoryName) {
        case 'All':
            return true;
        case 'Assigned':
            return obj.isAssigned;
        case 'Not Assigned':
            return !obj.isAssigned;
        default:
            return true;
    }
};

const hasMasterLevelCallback = (obj: IUserSkillDTO | IAllOutcomeDTO, categoryName: string) => {
    return obj.maxLevel === ESkillLevel.MASTER;
};

const hasMasterLevelAdminOutcomesCallback = (obj: IAdminOutcomeVM) => {
    return obj.outcome.maxLevel === ESkillLevel.MASTER;
};

const organizationPriorityOutcomesCallback = (obj: IAdminOutcomeVM) => {
    return obj.isFavorite;
};

// const requestedMasterLevelCallback = (obj: IUserSkillVM | IUserOutcomeVM, categoryName: string) => {
//     return obj.isMasterFocus;
// };

// const bestPracticeLevelCallback = (obj: IUserSkillDTO) => {
//     return obj.skillType === ESkillType.BEST_PRACTICE;
// };

export const licenseDepartmentFilterCallback = (obj: ILicenseVM, departmentName: string) => {
    return obj.department === departmentName;
};

export const licenseCountryFilterCallback = (obj: IAdUserVM, country: string) => {
    return obj.country === country;
};

export const licenseTypesFilterCallback = (obj: IAdUserVM, licenseType: string) => {
    return obj.sourceLicenseType === licenseType;
};

export const roleDepartmentFilterCallback = (obj: IRoleVM, department: string) => {
    return obj.department === department;
};

export const roleCountryFilterCallback = (obj: IRoleVM, country: string) => {
    return obj.country === country;
};

export const roleConcreteRoleFilterCallback = (obj: IRoleVM, role: EUserRoleLogical, key: any) => {
    return obj.roles.find((objRole) => {
        return EUserRoleLogical[objRole] === key;
    });
};

export const adminOutcomesUserDepartmentFilterCallback = (obj: IUserAssignOutcomeVM, departmentName: string) => {
    return obj.department === departmentName;
};

export const adminOutcomesUserRegionFilterCallback = (obj: IUserAssignOutcomeVM, region: string) => {
    return obj.region === region;
};

export const adminOutcomesUserLicenseTypesFilterCallback = (obj: IUserAssignOutcomeVM, licenseType: string) => {
    return obj.licenseType === licenseType;
};

export const adminHistoryByStatusFilterCallback = (obj: ITenantHistoryVM, status: string) => {
    return convertHistoryStatusToStringValues(obj.status) === status;
};

export const adminHistoryByActionNameFilterCallback = (obj: ITenantHistoryVM, actionName: string) => {
    return convertHistoryTypeToStringValues(obj.type) === actionName;
};

export const adminHistoryByCreatedByFilterCallback = (obj: ITenantHistoryVM, createdBy: string) => {
    return obj.initiatorName === createdBy;
};

export const adminHistoryDetailsByDepartmentFilterCallback = (obj: IAdminHistoryDetailsVM, department: string) => {
    return obj.department === department;
};

export const adminHistoryDetailsByStatusFilterCallback = (obj: IAdminHistoryDetailsVM, status: string) => {
    return obj.status === status;
};

export const insightsOrganizationUsersByDepartmentFilterCallback = (
    obj: IInsightsOrganizationUsersCompetenceVM,
    department: string
) => {
    return obj.department === department;
};

export const insightsOrganizationUsersByRegionFilterCallback = (
    obj: IInsightsOrganizationUsersCompetenceVM,
    region: string
) => {
    return obj.region === region;
};

export const insightsOrganizationSkillByDepartmentFilterCallback = (
    obj: IInsightsOrganizationSkillVM,
    department: string
) => {
    return obj.department === department;
};

export const insightsOrganizationSkillByRegionFilterCallback = (obj: IInsightsOrganizationSkillVM, region: string) => {
    return obj.region === region;
};

export const insightsOrganizationBadgesByDepartmentFilterCallback = (
    obj: IInsightsOrganizationBadgesVM,
    department: string
) => {
    return obj.department === department;
};

export const insightsOrganizationBadgesByRegionFilterCallback = (
    obj: IInsightsOrganizationBadgesVM,
    region: string
) => {
    return obj.region === region;
};

export const insightsOrganizationEngagementByDepartmentFilterCallback = (
    obj: IInsightsOrganizationEngagementVM,
    department: string
) => {
    return obj.department === department;
};

export const insightsOrganizationEngagementByRegionFilterCallback = (
    obj: IInsightsOrganizationEngagementVM,
    region: string
) => {
    return obj.region === region;
};

export const insightsOrganizationOutcomesUsersByDepartmentFilterCallback = (
    obj: IInsightsOrganizationOutcomesUserVM,
    department: string
) => {
    return obj.department === department;
};

export const insightsOrganizationOutcomesUsersByRegionFilterCallback = (
    obj: IInsightsOrganizationOutcomesUserVM,
    region: string
) => {
    return obj.region === region;
};

const organizationPriorityCallback = (obj: IAllOutcomeDTO) => {
    return obj.isCompanyFavorite;
};

export const outcomeCategories: IFilterCategories[] = [
    {
        radioGroupId: 'outcomes',
        name: 'Outcomes',
        radioButtonMode: false,
        defaultOpen: true,
        values: [
            {
                key: 'notAssigned',
                name: 'Not Assigned',
                callback: statusFilterCallback
            },
            {
                key: 'hasMasterLevel',
                name: 'Has Master Level',
                callback: hasMasterLevelCallback
            },
            // {
            //     key: 'requestedMasterLevel',
            //     name: 'Requested Master Level',
            //     callback: requestedMasterLevelCallback,
            // },
            {
                key: 'organizationImportant',
                name: 'Organization Priority',
                callback: organizationPriorityCallback
            }
        ]
    },
    {
        radioGroupId: 'app',
        name: 'App',
        radioButtonMode: false,
        defaultOpen: false,
        values: []
    },
    {
        name: 'Category',
        radioButtonMode: false,
        values: []
    }
];

export const skillCategories = [
    {
        radioGroupId: 'skills',
        name: 'Skills',
        radioButtonMode: false,
        defaultOpen: true,
        values: [
            {
                key: 'notAssigned',
                name: 'Not Assigned',
                callback: statusFilterCallback
            },
            // {
            //     key: 'bestPractice',
            //     name: 'Supplemental',
            //     callback: bestPracticeLevelCallback,
            // },
            {
                key: 'hasMasterLevel',
                name: 'Master Level Available',
                callback: hasMasterLevelCallback
            }
        ]
    },
    {
        name: 'App',
        radioButtonMode: false,
        defaultOpen: false,
        values: []
    },
    {
        name: 'Category',
        radioButtonMode: false,
        defaultOpen: false,
        values: []
    }
];

export const licenseCategories = [
    {
        radioGroupId: 'allDepartments',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    },
    {
        radioGroupId: 'allLicenseTypes',
        name: 'All License Types',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All License Types',
        values: []
    },
    {
        radioGroupId: 'allRegions',
        name: 'All Regions',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Regions',
        values: []
    }
];

export const roleCategories = [
    {
        radioGroupId: 'allDepartments',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    },
    {
        radioGroupId: 'allRegions',
        name: 'All Regions',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Regions',
        values: []
    },
    {
        radioGroupId: 'allRoles',
        name: 'All Roles',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Roles',
        values: []
    }
];

export const adminOutcomesOutcomesSelectionCategories: IFilterCategories[] = [
    {
        radioGroupId: 'outcomes',
        name: 'Outcomes',
        radioButtonMode: false,
        defaultOpen: true,
        values: [
            {
                key: 'hasMasterLevel',
                name: 'Has Master Level',
                callback: hasMasterLevelAdminOutcomesCallback
            },
            {
                key: 'organizationImportant',
                name: 'Organization Priority',
                callback: organizationPriorityOutcomesCallback
            }
        ]
    },
    {
        radioGroupId: 'app',
        name: 'App',
        radioButtonMode: false,
        defaultOpen: false,
        values: []
    },
    {
        name: 'Category',
        radioButtonMode: false,
        values: []
    }
];

export const adminOutcomeUserSelectionCategories = [
    {
        radioGroupId: 'allDepartments',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    },
    {
        radioGroupId: 'allLicenseTypes',
        name: 'All License Types',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All License Types',
        values: []
    },
    {
        radioGroupId: 'allRegions',
        name: 'All Regions',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Regions',
        values: []
    }
];

export const adminHistoryCategories = [
    {
        radioGroupId: 'status',
        name: 'All Statuses',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Statuses',
        values: [],
        operator: EFilterOperator.AND
    },
    {
        radioGroupId: 'actionName',
        name: 'All Action Names',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Action Names',
        values: [],
        operator: EFilterOperator.OR
    },
    {
        radioGroupId: 'createdBy',
        name: 'Created By',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'Created By',
        values: [],
        operator: EFilterOperator.AND
    }
];

export const adminHistoryDetailsCategories = [
    {
        radioGroupId: 'status',
        name: 'All Statuses',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Statuses',
        values: []
    },
    {
        radioGroupId: 'department',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    }
];

export const insightsOrganizationUsersCategories = [
    {
        radioGroupId: 'department',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    },
    {
        radioGroupId: 'region',
        name: 'All Regions',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Regions',
        values: []
    }
];

export const insightsOrganizationSkillCategories = [
    {
        radioGroupId: 'department',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    },
    {
        radioGroupId: 'region',
        name: 'All Regions',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Regions',
        values: []
    }
];

export const insightsOrganizationBadgesCategories = [
    {
        radioGroupId: 'department',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    },
    {
        radioGroupId: 'region',
        name: 'All Regions',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Regions',
        values: []
    }
];

export const insightsOrganizationEngagementCategories = [
    {
        radioGroupId: 'department',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    },
    {
        radioGroupId: 'region',
        name: 'All Regions',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Regions',
        values: []
    }
];

export const insightsOrganizationOutcomesUsersCategories = [
    {
        radioGroupId: 'department',
        name: 'All Departments',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Departments',
        values: []
    },
    {
        radioGroupId: 'region',
        name: 'All Regions',
        radioButtonMode: true,
        defaultOpen: false,
        defaultValue: 'All Regions',
        values: []
    }
];
