import { createContext, FC, useContext, useEffect, useState, PropsWithChildren } from 'react';
import {
    IUserImageResponse,
    useGetCurrentUserImageQuery,
    useGetCurrentUserQuery,
    usePostUserContentTrackingQuery
} from '../services/UserQueryService';
import { ICurrentUserVM } from '../interfaces/views/ICurrentUserVM';
import { EUserRoleImplementation } from '../interfaces/enums/EUserRoleImplementation';
import { ETenantPreference } from '../interfaces/enums/ETenantPreference';
import { EUserContentTrackingType } from '../interfaces/enums/EUserContentTrackingType';
import useAxiosInterceptors from '../hooks/useAxiosInterceptors';

export interface IUserAuthContext {
    userImage?: IUserImageResponse | null;
    currentUserData?: ICurrentUserVM & { roles: EUserRoleImplementation[] };
    refetchCurrentUserData: () => void;
    isTenantPreferenceActive: (tenantPreference: ETenantPreference) => boolean;
    sendContentUsageRequest: () => Promise<void>;
    isCurrentUserDataLoading?: boolean;
}

export const UserAuthContext = createContext<IUserAuthContext>({} as IUserAuthContext);

interface IProps {}

export const UserAuthProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [userImage, setUserImage] = useState<IUserImageResponse | undefined>(undefined);
    const {
        data: fetchedCurrentUserData,
        refetch: refetchCurrentUserData,
        isLoading: isCurrentUserDataLoading
    } = useGetCurrentUserQuery();
    const [currentUserData, setCurrentUserData] = useState<ICurrentUserVM | undefined>(undefined);
    const { mutateAsync: mutatePostUserContentTrackingAsync } = usePostUserContentTrackingQuery();
    const { data: currentUserImageData } = useGetCurrentUserImageQuery();
    useAxiosInterceptors();

    useEffect(() => {
        setUserImage(currentUserImageData);
    }, [currentUserImageData]);

    useEffect(() => {
        if (fetchedCurrentUserData) setCurrentUserData(fetchedCurrentUserData);
    }, [fetchedCurrentUserData]);

    const isTenantPreferenceActive = (tenantPreference: ETenantPreference) => {
        return currentUserData?.tenantPreferences.find((preference) => preference === tenantPreference) !== undefined;
    };

    const sendContentUsageRequest: () => Promise<void> = async () => {
        try {
            await mutatePostUserContentTrackingAsync({
                contentType: EUserContentTrackingType.HELP_WIZARD
            });
        } catch (e) {
            console.error(e);
        }
    };

    const userAuthContext: IUserAuthContext = {
        userImage,
        currentUserData,
        refetchCurrentUserData,
        isTenantPreferenceActive,
        sendContentUsageRequest,
        isCurrentUserDataLoading
    };

    return <UserAuthContext.Provider value={userAuthContext}>{children}</UserAuthContext.Provider>;
};

export const useUserAuthStateValue: () => IUserAuthContext = () => useContext(UserAuthContext);
